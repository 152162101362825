<template>
  <v-container fluid>
    <v-card>
      <v-card-title>{{ $t("m.referral.title") }}</v-card-title>
      <v-card-subtitle>
        <span v-html="$t('m.referral.subtitle')" />
      </v-card-subtitle>
      <v-card-text v-if="isStaffUrlEmpty">
        <v-row justify="center">
          <v-col cols="auto" align-self="center">
            <v-progress-circular
              color="primary"
              :indeterminate="isStaffUrlEmpty"
            ></v-progress-circular>
          </v-col>
          <v-col cols="auto" align-self="center">{{
            $t("m.referral.creating")
          }}</v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else>
        <v-row justify="center">
          <VueQr :dotScale="0.5" :text="staffUrl" />
        </v-row>
        <v-row justify="center">
          <v-col align-self="center" cols="auto">
            <a :href="staffUrl">{{ staffUrl }}</a>
          </v-col>
          <v-col align-self="center" cols="auto">
            <v-btn icon @click="download">
              <v-icon>mdi-download-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <a
      v-show="false"
      ref="doDownload"
      :href="qrCodeUrl"
      download="referral-qr-code.png"
      >click</a
    >

    <div
      id="qrCodeCard"
      ref="qrCodeCard"
      style="opacity: 0; position: absolute; top: 0; left: 0"
    >
      <v-card>
        <v-card-title>{{ $t("m.referral.qrCodeCard.title") }}</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <VueQr :dotScale="0.5" :text="staffUrl" />
          </v-row>
          <v-row justify="center">
            <v-col align-self="center" cols="auto">
              <a :href="staffUrl">{{ staffUrl }}</a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import html2canvas from 'html2canvas'
import { mapState } from 'vuex'
import VueQr from 'vue-qr'
import { encodeCampaignInfo } from '@/helpers/campaign-info'
import { shrinkUrl } from '@/helpers/referral'
import { addQRCode } from '@/helpers/firebase-functions'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    VueQr
  },
  data: () => ({
    staffUrl: '',
    qrCodeUrl: ''
  }),
  computed: {
    ...mapState('auth', ['uid']),
    isStaffUrlEmpty () {
      return this.staffUrl === ''
    }
  },
  methods: {
    async download () {
      const getUrl = (canvas) => {
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            resolve(URL.createObjectURL(blob))
          })
        })
      }

      const canvas = await html2canvas(this.$refs.qrCodeCard, {
        height: 600,
        onclone (cloned) {
          cloned.getElementById('qrCodeCard').style.opacity = 1.0
        }
      })
      this.qrCodeUrl = await getUrl(canvas)
      this.$nextTick(() => {
        this.$refs.doDownload.click()
      })
    }
  },
  async created () {
    const absoluteUrl = window ? window.location.origin : ''
    const ownerId = this.uid
    const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID
    // this.staffUrl = await shrinkUrl(`${absoluteUrl}/staff/${ownerId}`)

    const campaignInfo = encodeCampaignInfo({ ownerId, ownerScenarioId, campaignId: 'ownerReferral', campaignType: 'referral' })
    this.staffUrl = await shrinkUrl(`${absoluteUrl}/staff/${ownerId}?campaignInfo=${campaignInfo}`)

    const qrCode = {
      label: this.$i18n.t('m.referral.label'),
      url: this.staffUrl,
      enabled: true,
      count: 0,
      qrCodeId: 'ownerReferral',
      qrCodeType: 'referral'
    }
    addQRCode({ ownerId, scenarioId: ownerScenarioId, qrCode })
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_REFFERAL)
  }
}
</script>
